import Plyr from 'plyr-react';
import { PlyrWrapperModel } from '@atoms/PlyrWrapper/PlyrWrapper.model';
import { memo } from 'react';

const PlyrWrapper = memo(function PlyrWrapper({ videoUrl, options = {} }: PlyrWrapperModel) {
  const defaultOptions = {
    fullscreen: { enabled: true, fallback: true, iosNative: true },
    autoplay: false,
    controls: ['play', 'rewind', 'fullscreen', 'progress'],
    hideControls: true,
    muted: false,
    debug: false,
    youtube: { showinfo: 0, modestbranding: 1, rel: 0, controls: 0 },
    iconUrl: '/assets/images/plyr.svg',
    captions: {
      active: true,
    },
  };

  const mergedOptions = {
    ...defaultOptions,
    ...options,
    youtube: {
      ...defaultOptions.youtube,
      ...options.youtube,
    },
  };

  return (
    <Plyr
      options={mergedOptions}
      source={{
        type: 'video',
        previewThumbnails: {
          enabled: false,
        },
        sources: [
          {
            src: videoUrl,
            provider: 'youtube',
          },
        ],
      }}
    />
  );
});

export default PlyrWrapper;
