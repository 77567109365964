import { useEffect, useRef, useState } from 'react';
import { PlyrVideoProps } from '@molecules/PlyrVideo/PlyrVideo.props';
import Box from '@mui/material/Box';
import styles from './PlyrVideo.module.scss';
import PlyrWrapper from '@atoms/PlyrWrapper/PlyrWrapper';

const PlyrVideo = ({ videoUrl }: PlyrVideoProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [boxClassName, setBoxClassName] = useState(styles.Plyr);

  useEffect(() => {
    const mousemoveEventHandler = ({ x, y }: MouseEvent) => {
      setMousePosition({ x, y });
    };

    ref.current.addEventListener('mousemove', mousemoveEventHandler);

    () => {
      ref.current?.removeEventListener('mousemove', mousemoveEventHandler);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setBoxClassName(`${styles.Plyr} ${styles.PlyrHideControls}`);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
      setBoxClassName(`${styles.Plyr}`);
    };
  }, [mousePosition]);

  return (
    <Box ref={ref} className={boxClassName}>
      <PlyrWrapper videoUrl={videoUrl} />
    </Box>
  );
};

export default PlyrVideo;
